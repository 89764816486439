import * as React from 'react';
import Header from "../../components/common/header/Header";
import {
  Box,
  Grid,
  Stack,
  Link,
  Typography,
  Breadcrumbs,
  Button,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import theme from "../../theme";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ClientSideMenu from "./ClientSideMenu";
import Pagination from '@mui/material/Pagination';

function createData(
   documentNr: string,
   date: string,
   descriotion: string,
   debit: number,
   credit: number,
 ) {
   return { documentNr, date, descriotion, debit, credit };
 }
 
 const rows = [
   createData('000234234', '01.02.2024 - 13:00 AM', 'Description', 24, 4.0),
   createData('0000234234', '01.02.2024 - 13:00 AM', 'Description', 37, 4.3),
   createData('000234234', '01.02.2024 - 13:00 AM', 'Description', 24, 6.0),
   createData('000234234', '01.02.2024 - 13:00 AM', 'Description', 67, 4.3),
   createData('000343221', '01.02.2024 - 13:00 AM', 'Description', 49, 3.9),
   createData('', '01.02.2024 - 13:00 AM', 'Description', 49, 3.9),
 ];
 

const BalancePage = () => {
   return (
   
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <>
        <Header />
        <Box
          sx={{
            flexGrow: 1,
            m: 2,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={2.5}>
              <ClientSideMenu />
            </Grid>
            <Grid item xs={12} sm={12} md={9.5}>
              <Box
                sx={{
                  bgcolor: "#f1f1f1",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: 1,
                  borderTop: `2px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    sx={{ paddingLeft: "0px !important" }}
                  >
                    <Stack
                      spacing={2}
                      sx={{ paddingTop: "3px", paddingLeft: "5px" }}
                    >
                      <Breadcrumbs
                        separator={
                          <NavigateNextIcon
                            sx={{ color: "black" }}
                            fontSize="small"
                          />
                        }
                        aria-label="breadcrumb"
                      >
                        <Link
                          underline="hover"
                          key="2"
                          sx={{
                            color: theme.palette.text.primary,
                            ml: 1,
                            fontWeight: 400,
                            fontSize: "15px",
                            fontFamily: "Inter",
                          }}
                          href="/"
                        >
                          Home
                        </Link>
                        ,
                        <Typography
                          key="3"
                          sx={{
                            color: theme.palette.text.primary,
                            fontWeight: 600,
                            fontSize: "15px",
                            fontFamily: "Inter",
                          }}
                        >
                          Balance
                        </Typography>
                      </Breadcrumbs>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        pr: 1,
                      }}
                    >
                      <Box>
                        <DatePicker
                          format="MM/DD/YYYY"
                          sx={{
                            bgcolor: "#fff",
                            mr: 1,
                            width: "160px",
                            "& .MuiOutlinedInput-root": {
                              height: "30px",
                              borderRadius: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 0,
                            },
                            "& .MuiInputBase-input": {
                              height: "30px",
                              padding: "0 14px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                          }}
                          defaultValue={dayjs("2024-06-12")}
                        />
                        <DatePicker
                          format="MM/DD/YYYY"
                          sx={{
                            bgcolor: "#fff",
                            width: "160px",
                            "& .MuiOutlinedInput-root": {
                              height: "30px",
                              borderRadius: 0,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 0,
                            },
                            "& .MuiInputBase-input": {
                              height: "30px",
                              padding: "0 14px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                            },
                          }}
                          defaultValue={dayjs("2024-06-12")}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ overflowX: "auto" }}>
               {/* Table */}
               <TableContainer component={Paper} sx={{ boxShadow: "none", minWidth: "800px" }}>
                  <Table sx={{ minWidth: 650, boxShadow: 0 }} aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell sx={{fontFamily: "Inter", fontWeight: 600, pb:0}}>Document No</TableCell>
                        <TableCell align="left" sx={{fontFamily: "Inter", fontWeight: 600, pb:0}}>Date & Time</TableCell>
                        <TableCell align="left" sx={{fontFamily: "Inter", fontWeight: 600, pb:0}}>Description</TableCell>
                        <TableCell align="right" sx={{fontFamily: "Inter", fontWeight: 600, pb:0}}>Debit</TableCell>
                        <TableCell align="right" sx={{fontFamily: "Inter", fontWeight: 600, pb:0}}>Credit</TableCell>
                        <TableCell align="right" sx={{fontFamily: "Inter", fontWeight: 600, pb:0}}>Balance</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rows.map((row) => (
                        <TableRow
                        key={row.documentNr}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                           {row.documentNr}
                        </TableCell>
                        <TableCell align="left">{row.date}</TableCell>
                        <TableCell align="left">{row.descriotion}</TableCell>
                        <TableCell align="right">{row.debit}</TableCell>
                        <TableCell align="right">{row.credit}</TableCell>
                        <TableCell align="right"></TableCell>
                        </TableRow>
                     ))}
                     <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                           
                        </TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">150.00 EUR</TableCell>
                        <TableCell align="right">110.00 EUR</TableCell>
                        <TableCell align="right">550.00 EUR</TableCell>
                        </TableRow>
                  </TableBody>
                  </Table>
                  <Box sx={{display:"flex",bgcolor:"#f1f1f1", justifyContent:"flex-end", borderTop: "1px solid #d8d8d8f1"}}>
                  <Button sx={{ fontSize: '13px', fontFamily: "Inter", fontWeight: 600, textTransform: "capitalize", borderRadius: 0 }}>Export PDF</Button>
                  <Button sx={{ fontSize: '13px', fontFamily: "Inter", fontWeight: 600, textTransform: "capitalize", borderRadius: 0 }}>Print</Button>
               </Box>
               </TableContainer>
               {/* Pagination */}
                <Stack spacing={2}>
                  <Box sx={{display:"flex", justifyContent: "flex-end", pt: 1}}>
                     <Pagination count={10} variant="outlined" shape="rounded" />
                  </Box>
                  
               </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
      </LocalizationProvider>
      
   )
}

export default BalancePage;