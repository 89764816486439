import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "../../ui/Button";
import { setCartItems } from "../../../store/reducers/basket";
import { getBasketProducts } from "../../../services/core-api";
import theme from "../../../theme";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  border: "none",
  ...theme.typography.body2,
  padding: theme.spacing(1.6),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ITEM_HEIGHT = 90;

const BasketDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const [isBasketFetched, setIsBasketFetched] = React.useState(false);
  const dispatch = useDispatch();
  const defaultImage = "./no-image.png";
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    (async () => {
      const basket = await getBasketProducts();
      if (basket) {
        dispatch(setCartItems(basket.items));
        setIsBasketFetched(true);
      }
    })();
  }, [dispatch]);

  // Calculate the total price from the cart items
  const totalPrice = cartItems
    .reduce((sum, item) => {
      const price = item.totalPrice ? Number(item.totalPrice) : 0;
      return sum + price;
    }, 0)
    .toFixed(2);

  const itemCount = cartItems?.length || 0;

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Tooltip title="Shporta">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 0, p: 0, width: "100%" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ bgcolor: "primary.main", padding: "4px", width: "100%" }}
            >
              <Item
                sx={{
                  flexGrow: 1,
                  fontSize: "14px",
                  padding: 0,
                  textAlign: "left",
                  boxShadow: "none",
                }}
              >
                <span>
                  <ShoppingCartOutlinedIcon
                    sx={{ verticalAlign: "middle", fontSize: "25px" }}
                  />{" "}
                  {itemCount} Articles
                </span>
              </Item>
              <Item sx={{ boxShadow: "none" }}></Item>
              <Item
                sx={{
                  flexGrow: 1,
                  color: "secondary.main",
                  fontSize: "14px",
                  padding: 0,
                  textAlign: "right",
                  boxShadow: "none",
                }}
              >
                <Typography
                  paragraph={true}
                  sx={{
                    verticalAlign: "middle",
                    fontWeight: "bold",
                    margin: 0,
                    fontSize: "16px",
                  }}
                >
                  {totalPrice} EUR
                  <KeyboardArrowDownIcon
                    sx={{ verticalAlign: "middle", color: "primary.light" }}
                  />
                </Typography>
              </Item>
            </Stack>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0.5,
            width: "20rem",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Grid container spacing={0} sx={{ padding: "5px 15px" }}>
          <Grid item xs={9}>
            <Item sx={{ background: "none", boxShadow: "none", padding: 0 }}>
              <Typography
                variant="caption"
                height={"100%"}
                color="text.primary"
                component="div"
                sx={{ textAlign: "left" }}
              >
                Your basket - {itemCount} Articles
              </Typography>
            </Item>
          </Grid>
          <Grid item alignItems="bottom" xs={3}>
            <Item
              sx={{
                height: "100%",
                padding: 0,
                background: "none",
                boxShadow: "none",
              }}
            >
              <Typography
                variant="caption"
                height={"100%"}
                component="div"
                sx={{ textAlign: "right" }}
              >
                <Link href="/basket" underline="hover" color="text.primary">
                  Edit
                </Link>
              </Typography>
            </Item>
          </Grid>
        </Grid>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: ITEM_HEIGHT * 4.5,
          }}
        >
          {cartItems &&
            cartItems.length > 0 &&
            cartItems.map((item: any, index: number) => {
              const price = Number(item.price).toFixed(2);
              return (
                <MenuItem
                  key={index}
                  onClick={handleClose}
                  sx={{
                    "--Grid-borderWidth": "1px",
                    pt: 2,
                    pb: 2,
                    borderTop: "var(--Grid-borderWidth) solid",
                    borderColor: "divider",
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={2}>
                      <Item
                        sx={{
                          background: "none",
                          boxShadow: "none",
                          padding: 0,
                        }}
                      >
                        <img
                          style={{ width: "100%" }}
                          alt="Beautiful landscape"
                          src={item.image || defaultImage}
                        />
                      </Item>
                    </Grid>
                    <Grid item xs={6}>
                      <Item
                        sx={{
                          padding: 0,
                          mb:2,
                          marginLeft: "1rem",
                          background: "none",
                          boxShadow: "none",
                        }}
                      >
                        <Typography
                          component="div"
                          variant="body2"
                          color="text.primary"
                          sx={{
                            textAlign: "left",
                            fontSize: "0.7rem !important",
                          }}
                        >
                          Quantity: {item.quantity}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.primary"
                          component="div"
                          sx={{
                            textAlign: "left",
                            fontSize: "0.7rem !important",
                          }}
                        >
                          Article Number: {item.articleNumber}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.primary"
                          component="div"
                          sx={{
                            textAlign: "left",
                            fontSize: "0.7rem !important",
                          }}
                        >
                          Manufacturer: {item.mfrName}
                        </Typography>
                      </Item>
                    </Grid>
                    <Grid item alignItems="bottom" xs={4}>
                      <Item
                        sx={{
                          position: "relative",
                          marginTop: "10px",
                          height: "100%",
                          padding: 0,
                          background: "none",
                          boxShadow: "none",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="secondary.main"
                          component="div"
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                          }}
                        >
                          {price} EUR
                        </Typography>
                      </Item>
                    </Grid>
                  </Grid>
                </MenuItem>
              );
            })}
        </Box>
        <Grid
          container
          spacing={0}
          sx={{
            padding: "5px 15px",
            "--Grid-borderWidth": "1px",
            borderTop: "var(--Grid-borderWidth) solid",
            borderColor: "divider",
          }}
        >
          <Grid item xs={8}>
            <Item
              sx={{
                background: "none",
                boxShadow: "none",
                padding: 0,
              }}
            >
              <Typography
                variant="caption"
                height={"100%"}
                color="text.primary"
                component="div"
                sx={{ fontSize: "16px", fontFamily: "Inter", textAlign: "left", fontWeight: 'bold' }}
              >
                Total price
              </Typography>
            </Item>
          </Grid>
          <Grid item alignItems="bottom" xs={4}>
            <Item
              sx={{
                height: "100%",
                padding: 0,
                background: "none",
                boxShadow: "none",
              }}
            >
              <Typography
                variant="subtitle2"
                color="text.primary"
                component="div"
                sx={{ fontSize: "16px", fontFamily: "Inter", textAlign: "right", fontWeight: 'bold' }}
              >
                {totalPrice} EUR
              </Typography>
            </Item>
          </Grid>
          <Grid item alignItems="bottom" xs={12} sx={{ marginTop: 5 }}>
            <Item
              sx={{
                height: "100%",
                padding: 0,
                background: "none",
                boxShadow: "none",
              }}
            >
              <Link href="/basket" underline="none" color="text.primary">
                <Button
                  sx={{
                    width: "100%",
                    bgcolor: theme.palette.secondary.main,
                    color: "white",
                    ":hover": {
                      bgcolor: "#cf6622",
                    },
                  }}
                >
                  Basket
                </Button>
              </Link>
            </Item>
          </Grid>
        </Grid>
      </Menu>
    </React.Fragment>
  );
};

export default BasketDropdown;
