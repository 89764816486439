import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BasketItem } from "../../interfaces/core/BasketItem";

interface CartState {
   items: BasketItem[];
}

const initialState: CartState = {
   items: []
}

const cartSlice = createSlice({
   name: 'cart',
   initialState,
   reducers: {
      addToCart: (state, action: PayloadAction<BasketItem>) => {
         const { productId, internalNumber, quantity, articleNumber, mfrName, dataSupplierId, image, description, price } = action.payload;
         const itemIndex = state.items.findIndex(item => item.productId === productId);
         
         const parsedPrice = parseFloat(price.toString());
         const parsedQuantity = Number(quantity);

         if (itemIndex >= 0) {
            state.items[itemIndex].quantity += parsedQuantity;
            state.items[itemIndex].totalPrice = parseFloat((state.items[itemIndex].quantity * state.items[itemIndex].price).toFixed(2));
         } else {
            state.items.push({
               productId,
               internalNumber,
               quantity: parsedQuantity,
               articleNumber,
               mfrName,
               dataSupplierId,
               image,
               description,
               price: parseFloat(parsedPrice.toFixed(2)),
               totalPrice: parseFloat((parsedQuantity * parseFloat(parsedPrice.toFixed(2))).toFixed(2)),
               available: true
            });
         }
      },
      setCartItems: (state, action: PayloadAction<BasketItem[]>) => {
         state.items = action.payload;
       },
      updateCartItemQuantity: (state, action: PayloadAction<{ productId: number, quantity: number }>) => {
         const { productId, quantity } = action.payload;
         const itemIndex = state.items.findIndex(item => item.productId === productId);

         if (itemIndex >= 0) {
            state.items[itemIndex].quantity = quantity;
            state.items[itemIndex].totalPrice = parseFloat((quantity * state.items[itemIndex].price).toFixed(2));
         }
      }
   }
});

export const { addToCart, setCartItems, updateCartItemQuantity } = cartSlice.actions;
export default cartSlice.reducer;
