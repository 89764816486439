import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Carousel from "../Carousel/Carousel";
import { Typography, AppBar, Tabs, Tab } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import ManualSearch from "./ManualSearch";
import VehicleIdentification from "./VehicleIdentification";
import BrandProductGroupSearch from "../BrandProductGroupSearch/BrandProductGroupSearch";
import RecentSearch from "./RecentSearch";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: "none !important",
  borderRadius: 0,
  backgroundColor: "#F1F1F1 !important",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
  childrenProps?: (children: React.ReactNode) => React.ReactNode;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, childrenProps, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ minHeight:300 ,background: '#f1f1f1', padding: '0px 24px'}}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {childrenProps ? (
            childrenProps(children)
          ) : (
            <Typography component={"span"} variant="subtitle1" >{children}</Typography>
          )}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const CarModalSearch = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <Box>
      <Box sx={{ flexGrow: 1, m: 2 }}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={12} md={5}>
            <Item>
              <Box sx={{ bgcolor: "background.paper", width: '100%' }}>
                <AppBar position="static" style={{boxShadow: 'none'}}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                     <Tab 
                        icon={
                           <div style={{ display: 'flex', alignItems: 'center' }}>
                                 <img src='/manual_search_car.svg' alt="Search car icon" style={{ width:'24px', marginRight: '8px', fill: '#fff' }} />
                                 <Typography sx={{ display: {xs: 'none', sm: 'none', md: 'block'}, color: '#fff', fontSize: "13px", fontFamily:"Inter", textTransform: "capitalize" }}>Manual search</Typography>
                           </div>
                        }
                        {...a11yProps(0)} 
                     />
                     <Tab
                        icon={
                           <div style={{ display: 'flex', alignItems: 'center' }}>
                                 <img src='/search_white_car.svg' alt="Search car icon" style={{ width:'24px', marginRight: '8px', fill: '#fff' }} />
                                 <Typography sx={{ display: {xs: 'none', sm: 'none', md: 'block'}, color: '#fff', fontSize: "13px", fontFamily:"Inter", textTransform: "capitalize" }}>Vehicle identification</Typography>
                           </div>
                        }
                        {...a11yProps(1)}
                     />
                     <Tab
                        icon={
                           <div style={{ display: 'flex', alignItems: 'center' }}>
                                 <img src='/recent_icon.svg' alt="Search car icon" style={{ width:'24px', marginRight: '8px', fill: '#fff' }} />
                                 <Typography sx={{ display: {xs: 'none', sm: 'none', md: 'block'}, color: '#fff', fontSize: "13px", fontFamily:"Inter", textTransform: "capitalize" }}>Recent search</Typography>
                           </div>
                        }
                        {...a11yProps(2)}
                     />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                  style={{background: '#f1f1f1'}}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                     <ManualSearch></ManualSearch>
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                     <VehicleIdentification></VehicleIdentification>
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    <RecentSearch/>
                  </TabPanel>
                </SwipeableViews>
              </Box>
            </Item>
            <Item sx={{marginTop: '10px'}}>
              <BrandProductGroupSearch/>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <Item>
              <Carousel />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CarModalSearch;
