import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            light: '#fff',
            main: '#0C293F',
            dark: '#3A98B8'
        },
        secondary: {
            main: '#F37C2E',
            light: '#D9D9D9',
            dark: '#000'
        },
        text: {
            primary: '#5d5d5d',
            secondary: '#fff',
        }
    }
});

export default theme;