import React from "react";
import { styled } from "@mui/material/styles";
import Header from "../components/common/header/Header";
import CarModalSearch from "../components/common/CarModelSearch/CarModelSearch";
import Offers from "../components/common/BestSellProducts/BestSellProducts";
import { Box, Grid, Tab, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import theme from "../theme";
import TireSearch from "../components/common/TireSearch/TireSearch";
import SelectEngineRequirements from "../components/common/OilSearch/SelectEngineRequirements";
import SelectVehicle from "../components/common/OilSearch/SelectVehicle";
import { TabContext, TabList } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
import BatterySearch from "../components/common/BatterySearch/BatterySearch";
import Footer from "../components/common/Footer/Footer";

// Define the type of the Item component props
interface ItemProps {
  backgroundimage: string; // Declare the backgroundImage prop
  width: string;
}

const Item = styled(Paper)<ItemProps>(({ theme, backgroundimage, width }) => ({
  backgroundColor: "#f1f1f1",
  borderRadius: 0,
  boxShadow: "none",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  position: "relative",
  minHeight: "150px",
  overflow: "hidden", // Ensure the background image does not overflow the item
  "&::after": {
    content: '""',
    position: "absolute",
    top: 10,
    right: 8,
    bottom: 0,
    width: width, // Adjust the width of the background image
    background: `url(${backgroundimage}) no-repeat`, // Set the background image
    backgroundSize: "contain", // Ensure the image fits inside the defined area
    opacity: 0.7, // Adjust the opacity of the background image
  },
}));

const Home = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <Header />
      <CarModalSearch />
      <Box sx={{ flexGrow: 1, m: 2, marginTop: 3, background: "linear-gradient(90deg, rgba(0,212,255,0) 0%, rgba(12,41,63,1) 54%, rgba(2,0,36,1) 100%)", color:  theme.palette.common.white }}>
        <a href="https://tecmotion.co" target="_blank" rel="noreferrer">
        <Grid container spacing={1} sx={{color: theme.palette.text.secondary, textDecoration: 'none !important'}}>
            <Grid item xs={12} sm={12} md={4}>
                <img
                    src="./tecdoc_search_flow.png"
                    alt="TecDoc Search Flow Diagram"
                    style={{ height: "130px", flex:1}}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <Box sx={{m:1}}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold"}}>
                        Sell more parts with our Tecmotion catalogue solutions
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{fontSize:"13px"}}>
                        The automotive aftermarket is exceptional in its use and management of data.
                        In this data-driven environment, the TecDoc catalogue is the product  management industry standard.<br/>
                        Tecmotion Catalogue enables companies to work efficiently at all times and every point of sale and 
                        find the right part from A to Z.
                    </Typography>
                    <img 
                        style={{
                            width: '100px',
                            marginTop: 4
                        }}
                        src="./tecmotion_white_logo.png"
                        alt="White Logo - TECMOTION"
                    />
                </Box>
            </Grid>
        </Grid>
        </a>
      </Box>
      <Box sx={{ flexGrow: 1, m: 2, marginTop: 3 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                alignItems: "center",
                background: "#f1f1f1",
                color: theme.palette.text.secondary,
                width: "130px",
                padding: 1,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  background: theme.palette.primary.main,
                }}
              >
                <Typography variant="subtitle1" align="center" component="h2">
                  Tire
                </Typography>
              </Box>
            </Box>
            <Item backgroundimage="./tire.png" width="10%">
              <TireSearch />
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                alignItems: "center",
                background: "#f1f1f1",
                color: theme.palette.text.secondary,
                width: "130px",
                padding: 1,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  background: theme.palette.primary.main,
                }}
              >
                <Typography variant="subtitle1" align="center" component="h2">
                  Oil
                </Typography>
              </Box>
            </Box>
            <Item backgroundimage="./oil.png" width="40px">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <Typography
                  variant="body1"
                  align="left"
                  sx={{
                    fontSize: "13px",
                    color: theme.palette.text.primary,
                    m: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Search oil by
                </Typography>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label="viscosity"
                        value="1"
                        sx={{
                          color: theme.palette.text.primary, // Set the color of the tab text
                          "&.Mui-selected": {
                            color: theme.palette.secondary.main, // Set the color of the active tab text
                          },
                        }}
                      />
                      <Tab
                        label="vehicle"
                        value="2"
                        sx={{
                          color: theme.palette.text.primary, // Set the color of the tab text
                          "&.Mui-selected": {
                            color: theme.palette.secondary.main, // Set the color of the active tab text
                          },
                        }}
                      />
                    </TabList>
                  </Box>
                  <TabPanel
                    value="1"
                    sx={{
                      padding: 0,
                      "& .MuiBox-root.css-nk32ej": {
                        padding: 0,
                      },
                    }}
                  >
                    <SelectEngineRequirements />
                  </TabPanel>
                  <TabPanel
                    value="2"
                    sx={{
                      padding: 0,
                      "& .MuiBox-root.css-pfj5m7": {
                        padding: 0,
                      },
                    }}
                  >
                    <SelectVehicle />
                  </TabPanel>
                </TabContext>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box
              sx={{
                alignItems: "center",
                background: "#f1f1f1",
                color: theme.palette.text.secondary,
                width: "130px",
                padding: 1,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  background: theme.palette.primary.main,
                }}
              >
                <Typography variant="subtitle1" align="center" component="h2">
                  Battery
                </Typography>
              </Box>
            </Box>
            <Item backgroundimage="./battery.png" width="10%">
              <BatterySearch />
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Offers />
      <Footer/>
    </div>
  );
};

export default Home;
