import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProductOffer from "./BestSellProduct";
import { Box, Typography } from "@mui/material";
import theme from "../../../theme";
import { getBestSellProducts } from "../../../services/core-api";
import { BestSellProduct } from "../../../interfaces/core/BestSellProduct";


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const Offers = () => {

  const [bestSellProducts, setBestSellProducts] = React.useState<BestSellProduct[]>([])

  React.useEffect(() => {
    let activeBestSell = true;

    if (activeBestSell) {
      (async () => {
        try {
          const result = await getBestSellProducts();
          if (result) {
            setBestSellProducts(result);
          } 
        } catch (error) {
        }
      })();
    }
  }, [])

  return (
    <Box sx={{ flexGrow: 1, m: 2, marginTop: 3 }}>
        
        <Box
          sx={{ width: 'max-content', background: '#f1f1f1', padding: 1}}
        >
          <Typography variant="body1" gutterBottom 
            sx={{ 
              width: 'max-content', 
              background: theme.palette.primary.main, 
              padding: 1,
              color: theme.palette.text.secondary
            }}>
            Most Selling Products
          </Typography>
        </Box>
        <div className="parent">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={3000}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        transitionDuration={3000}
        dotListClass="custom-dot-list-style"
        
      >
        {bestSellProducts.map((product, index) => {
          return (
            <div className="slider" key={index}>
              <ProductOffer product={product}/>
            </div>
          );
        })}
      </Carousel>
    </div>
    </Box>
  );
};
export default Offers;
