import React from "react";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AssemblyBox from "./AssemblyBox";
import { getAssemblyGroupFacets } from "../../../services/tecdocService";
import { VehicleProductGroup } from "../../../interfaces/VehicleProductGroup";
import AssemblyTree from "./AssemblyTree";
import ShortcutAssemblies from "./ShortcutAssemblies";
import ProductBrands from "../../products/ProductBrands";
import { DataSupplierFacet } from "../../../interfaces/DataSupplierFacet";
import SearchLiveAssemblyGroups from "./SearchLiveAssemblyGroups";
import { changeGridView, getUser, getUsers } from "../../../services/core-api";

const Assemblies = () => {
  const theme = useTheme();
  const [isGridView, setIsGridView] = React.useState(true);
  const [isTreeView, setIsTreeView] = React.useState(false);
  const [vehicleProductGroups, setVehicleProductGroups] = React.useState<
    VehicleProductGroup[] | null
  >(null);
  const [linkageTargetId, setLinkageTargetId] = React.useState<number | null>(
    null
  );
  const [linkageTargetType, setLinkageTargetType] = React.useState<
    string | null
  >(null);
  const [brandId, setBrandId] = React.useState<number | null>(null);
  const [dataSupplierFacets, setDataSupplierFacets] =
    React.useState<DataSupplierFacet | null>(null);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const linkageTargetType = urlParams.get("linkageTargetType");
    const linkageTargetId = urlParams.get("linkageTargetId");
    const brandId = urlParams.get("brand");

    if (linkageTargetId) {
      const linkageTargetIdUrlParam = parseInt(linkageTargetId, 10);
      setLinkageTargetId(linkageTargetIdUrlParam);
    }

    if (linkageTargetType) {
      setLinkageTargetType(linkageTargetType);
    }

    if (brandId) {
      const brandIdUrlParam = parseInt(brandId, 10);
      setBrandId(brandIdUrlParam);
    }

  }, []);

  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = localStorage.getItem("user");
        if (user) {
          const userData = JSON.parse(user);
          const authUser = await getUser(userData.id);
          if (authUser) {
            console.log("Authenticated User:", authUser);
            // Perform any additional actions here
            setIsGridView(authUser.activate_grid_view);
            setIsTreeView(!authUser.activate_grid_view);
          }
        }
      } catch (error) {}
    };
  
    fetchUserData();
  }, []); // 

  React.useEffect(() => {
    if (linkageTargetId !== null && linkageTargetType !== null) {
      fetchAssemblyGroups(linkageTargetType, linkageTargetId);
    }
  }, [linkageTargetId, linkageTargetType]);

  const fetchAssemblyGroups = async (
    linkageTargetType: string,
    linkageTargetId: number
  ) => {
    try {
      const options: { brand?: number } = {};
      if (brandId !== null) {
        options.brand = brandId;
      }

      const response = await getAssemblyGroupFacets(
        linkageTargetType,
        linkageTargetId,
        options
      );
      if (response) {
        const assemblyGroupFacets = response.assemblyGroupFacets.counts;
        setVehicleProductGroups(assemblyGroupFacets);

        const dataSupplierFacets = response.dataSupplierFacets;
        setDataSupplierFacets(dataSupplierFacets);
      }
    } catch (error) {}
  };

  const handleViewChange = async (view: string) => {
    
    if (view === "tree") {
      try {
        const response = await changeGridView();
        if (response){
          setIsGridView(false);
          setIsTreeView(true);
        }
      }catch (error){}
      
    } else {
      setIsGridView(true);
      setIsTreeView(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "#f1f1f1",
          p: 1.1,
          borderTop: `2px solid ${theme.palette.primary.dark}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "15px",
              textAlign: "left",
            }}
          >
            Product Groups
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Inter",
                fontWeight: 500,
                fontSize: "15px",
                textAlign: "left",
                mr: 2,
              }}
            >
              Dashboard
            </Typography>
            {isGridView ? (
              <IconButton
                sx={{ color: `${theme.palette.secondary.main}` }}
                onClick={() => handleViewChange("grid")}
              >
                <GridViewOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                onClick={() => handleViewChange("grid")}
              >
                <GridViewOutlinedIcon />
              </IconButton>
            )}

            {isTreeView ? (
              <IconButton
                sx={{ color: `${theme.palette.secondary.main}` }}
                onClick={() => handleViewChange("tree")}
              >
                <MenuOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                onClick={() => handleViewChange("tree")}
              >
                <MenuOutlinedIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 1, mb: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ShortcutAssemblies />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          minHeight: 352,
          minWidth: 250,
          paddingLeft: 4,
          paddingRight: 4,
          paddingBottom: 4,

          border: "1px solid #f1f1f1",
        }}
      >
        <Box sx={{ pt: 2, pb: 2 }}>
          <Grid container spacing={0} justifyContent="flex-end">
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                padding: { xs: 0, sm: 0, md: 1 },
              }}
            >
              <Box flex={1}>
                <SearchLiveAssemblyGroups />
              </Box>
              <Box flex={1}>
                <ProductBrands dataSupplierFacets={dataSupplierFacets} />
              </Box>
            </Box>
          </Grid>
        </Box>

        {vehicleProductGroups ? (
          isGridView ? (
            <Grid container spacing={2}>
              {vehicleProductGroups.map((group: VehicleProductGroup) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={group.assemblyGroupNodeId}
                >
                  <AssemblyBox group={group} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <AssemblyTree group={vehicleProductGroups} />
          )
        ) : (
          <div>No data available</div>
        )}
      </Box>
    </>
  );
};

export default Assemblies;
