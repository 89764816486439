import { Article } from '../interfaces/Article';
import { ArticleLinkage } from '../interfaces/ArticleLinkage';
import { ArticlesResponse } from '../interfaces/ArticlesResponse';
import { AssemblyGroup } from '../interfaces/AssemblyGroup';
import { Brand } from '../interfaces/Brand';
import { Manufacturer } from '../interfaces/Manufacturer';
import { ManufacturerModel } from '../interfaces/ManufacturerModels';
import { PartlistDetailsResponse } from '../interfaces/PartlistDetailsResponse';
import { VehicleDetail } from '../interfaces/VehicleDetail';
import { VehicleProductGroup } from '../interfaces/VehicleProductGroup';
import axiosInstance from '../components/auth/interceptor';
import { LinkageVehicle } from '../interfaces/LinkageVehicle';
import { GenericArticleFacetCounts } from '../interfaces/GenericArticleFacetCounts';

const baseURL = process.env.REACT_APP_API_URL;

// Fetch Manufecturers by linkingTargetType
export async function fetchManufacturers(input: string): Promise<Manufacturer[]> {
  const response = await axiosInstance.get(`${baseURL}/manufacturers?linkingTargetType=${input}`);
  return response.data as Manufacturer[];
}

// Fetch models by Manufacturer
export async function fetchModels(manuId: number, linkingTargetType: string): Promise<ManufacturerModel[]>{
  const response = await axiosInstance.get(`${baseURL}/models?manufactureId=${manuId}&linkingTargetType=${linkingTargetType}`);
  return response.data as ManufacturerModel[];
}

// Fetch motors by Manufacture, Model and carType
export async function getVehicleImage(manuId: number, modId: number, carType: string): Promise<string | null>{
  const response = await axiosInstance.get(`${baseURL}/vehicleImage?manufacturerId=${manuId}&modId=${modId}&carType=${carType}`);
  return response.data[0];
}

// Fetch motors by Manufacture, Model and carType
export async function getLinkageTargets(manuId: number, modId: number, carType: string): Promise<VehicleDetail[]>{
  const response = await axiosInstance.get(`${baseURL}/motors?manufacturerId=${manuId}&modId=${modId}&carType=${carType}`);
  return response.data as VehicleDetail[];
}

// Fetch Brands
export async function fetchBrands(): Promise<Brand[]> {
  const response = await axiosInstance.get(`${baseURL}/brands`);
  return response.data as Brand[];
}

// Fetch Brands
export async function fetchBrandsLogo(dataSupplierIds: number[]): Promise<Brand[]> {
  const queryString = dataSupplierIds.join(',');
  const url = `${baseURL}/brandsLogo?dataSupplierIds=${queryString}`;
  const response = await axiosInstance.get(url);
  return response.data as Brand[];
}

// Fetch Brands
export async function fetchAssemblyGroups(brandId: number, assemblyGroupType: string): Promise<AssemblyGroup[]> {
  const response = await axiosInstance.get(`${baseURL}/assemblyGroups?brandId=${brandId}&assemblyGroupType=${assemblyGroupType}`);
  return response.data as AssemblyGroup[];
}

// Fetch articles by any number
 export async function fetchLiveArticles(input: string): Promise<Article[]> {
  const newValue = input.replace(/\//g, '*');
  const response = await axiosInstance.get(`${baseURL}/keyword=${newValue}`);
  return response.data.articles as Article[];
}

// Fetch article by articleNumber
export async function fetchArticle(input: string): Promise<Article> {
  const articleNumber = input.replace(/\//g, '*');
  const response = await axiosInstance.get(`${baseURL}/getArticle=${articleNumber}`);
  return response.data as Article;
}

// Fetch articles by any number
export async function fetchArticles(input: string, options: any): Promise<ArticlesResponse> {
  const { linkageTargetType, linkageTargetId, category, brand, page, criteria, assemblyGroupNodeIds } = options;
  const newValue = input.replace(/\//g, '*');
  
  // Initialize the base URL
  let url = `${baseURL}/search-products`;
  
  // Initialize an array to hold query parameters
  const params: string[] = [];
  
  // Add the keyword if it is not null or undefined
  if (newValue !== null && newValue !== undefined && newValue !== '') {
    params.push(`keyword=${newValue}`);
  }

  // Add the page parameter, with a default value if not provided
  if (page !== null && page !== undefined) {
    params.push(`page=${page}`);
  } else {
    params.push(`page=1`); // default page
  }

  // Add the category if it is not null or undefined
  if (category !== null && category !== undefined) {
    params.push(`category=${category}`);
  }

  // Add the brand if it is not null or undefined
  if (brand !== null && brand !== undefined) {
    params.push(`brand=${brand}`);
  }

  if (assemblyGroupNodeIds !== null && assemblyGroupNodeIds !== undefined) {
    params.push(`assemblyGroupNodeIds=${assemblyGroupNodeIds}`);
  }

  if (linkageTargetType !== null && linkageTargetType !== undefined) {
    params.push(`linkageTargetType=${linkageTargetType}`);
  }

  if (linkageTargetId !== null && linkageTargetId !== undefined) {
    params.push(`linkageTargetId=${linkageTargetId}`);
  }

  // Add criteria if it is not null, undefined, or empty
  if (criteria && criteria.length > 0) {
    const criteriaParams = criteria.map((criterion: { criteriaId: number; rawValue: string }) =>
      `criteria=${criterion.criteriaId}:${encodeURIComponent(criterion.rawValue)}`
    ).join('&');
    params.push(criteriaParams);
  }
  
  // Join all parameters with '&' and append to the base URL
  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  // Fetch data from the server
  const response = await axiosInstance.get(url);
  return response.data as ArticlesResponse;
}

// Fetch articles by any number
export async function fetchGenericArticleFacets(input: string, options: any): Promise<GenericArticleFacetCounts[]> {
  const { linkageTargetType, linkageTargetId, brand, page, assemblyGroupNodeIds } = options;
  const newValue = input.replace(/\//g, '*');
  
  // Initialize the base URL
  let url = `${baseURL}/getGenericArticleFacets`;
  
  // Initialize an array to hold query parameters
  const params: string[] = [];
  
  // Add the keyword if it is not null or undefined
  if (newValue !== null && newValue !== undefined && newValue !== '') {
    params.push(`keyword=${newValue}`);
  }

  // Add the page parameter, with a default value if not provided
  if (page !== null && page !== undefined) {
    params.push(`page=${page}`);
  } else {
    params.push(`page=1`); // default page
  }

  // Add the brand if it is not null or undefined
  if (brand !== null && brand !== undefined) {
    params.push(`brand=${brand}`);
  }

  if (assemblyGroupNodeIds !== null && assemblyGroupNodeIds !== undefined) {
    params.push(`assemblyGroupNodeIds=${assemblyGroupNodeIds}`);
  }

  if (linkageTargetType !== null && linkageTargetType !== undefined) {
    params.push(`linkageTargetType=${linkageTargetType}`);
  }

  if (linkageTargetId !== null && linkageTargetId !== undefined) {
    params.push(`linkageTargetId=${linkageTargetId}`);
  }

  // Join all parameters with '&' and append to the base URL
  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  // Fetch data from the server
  const response = await axiosInstance.get(url);
  return response.data as GenericArticleFacetCounts[];
}


// Fetch linking vehicles by articleId and linkageTargetType
export async function getlinkingVehicles(linkageTargetType: string, legacyArticleId: number): Promise<ArticleLinkage[]> {
  const response = await axiosInstance.get(`${baseURL}/getlinkingVehicles?linkageTargetType=${linkageTargetType}&legacyArticleId=${legacyArticleId}`);
  return response.data as ArticleLinkage[];
}

// Fetch linking vehicles by articleId and linkageTargetType
export async function getlinkingVehiclesByVIN(vin: string): Promise<LinkageVehicle[]> {
  const response = await axiosInstance.get(`${baseURL}/searchType?vin=${vin}`);
  return response.data as LinkageVehicle[];
}


export async function getlinkingVehiclesByEngineCode(engineCode: string): Promise<VehicleDetail[]> {
  const response = await axiosInstance.get(`${baseURL}/search-by-engine-code?engineCode=${engineCode}`);
  return response.data as VehicleDetail[];
}


// Fetch paartlist by articleId and linkageTargetType
export async function fetchPartList(linkageTargetType: string, legacyArticleId: number): Promise<PartlistDetailsResponse[]> {
  const response = await axiosInstance.get(`${baseURL}/getPartList?linkageTargetType=${linkageTargetType}&legacyArticleId=${legacyArticleId}`);
  return response.data as PartlistDetailsResponse[];
}

// Fetch vehiccle details by linkageTargetType and linkageTargetId
export async function fetchVehicleDetail(linkageTargetType: string, linkageTargetId: number): Promise<VehicleDetail> {
  const response = await axiosInstance.get(`${baseURL}/getVehicleDetail?linkageTargetType=${linkageTargetType}&linkageTargetId=${linkageTargetId}`);
  return response.data as VehicleDetail;
}

// Fetch vehiccle details by linkageTargetType and linkageTargetId
export async function fetchVehicleProductGroups(linkageTargetType: string, linkageTargetId: number, options: any): Promise<VehicleProductGroup[]> {
  const { parentNodeId } = options;

  let url = `${baseURL}/getVehicleProductGroups`;

  if (linkageTargetType !== null && linkageTargetType !== undefined) {
    url += `?linkageTargetType=${linkageTargetType}`;
  }

  if (linkageTargetId !== null && linkageTargetId !== undefined) {
    url += `&linkageTargetId=${linkageTargetId}`;
  }

  if (parentNodeId !== null && parentNodeId !== undefined) {
    url += `&parentNodeId=${parentNodeId}`;
  }

  const response = await axiosInstance.get(url);
  return response.data as VehicleProductGroup[];
}

// Fetch vehiccle details by linkageTargetType and linkageTargetId
export async function getVehicleProductGroupsChild(linkageTargetType: string, linkageTargetId: number, options: any): Promise<VehicleProductGroup[]> {
  const { parentNodeId } = options;

  let url = `${baseURL}/getVehicleProductGroupsChild`;

  if (linkageTargetType !== null && linkageTargetType !== undefined) {
    url += `?linkageTargetType=${linkageTargetType}`;
  }

  if (linkageTargetId !== null && linkageTargetId !== undefined) {
    url += `&linkageTargetId=${linkageTargetId}`;
  }

  if (parentNodeId !== null && parentNodeId !== undefined) {
    url += `&parentNodeId=${parentNodeId}`;
  }

  const response = await axiosInstance.get(url);
  return response.data as VehicleProductGroup[];
}

// Fetch vehiccle brand groups by linkageTargetType and linkageTargetId and brandId
export async function getAssemblyGroupFacets(linkageTargetType: string, linkageTargetId: number, options: any): Promise<any> {
  const {brand, assemblyGroupNodeIds, maxDepth} = options;

  let url = `${baseURL}/getAssemblyGroupFacets`;

  if (linkageTargetType !== null && linkageTargetType !== undefined) {
    url += `?linkageTargetType=${linkageTargetType}`;
  }

  if (linkageTargetId !== null && linkageTargetId !== undefined) {
    url += `&linkageTargetId=${linkageTargetId}`;
  }

  if (brand !== null && brand !== undefined) {
    url += `&brand=${brand}`;
  }

  if (assemblyGroupNodeIds !== null && assemblyGroupNodeIds !== undefined) {
    url += `&assemblyGroupNodeIds=${assemblyGroupNodeIds}`;
  }

  if (maxDepth !== null && maxDepth !== undefined) {
    url += `&maxDepth=${maxDepth}`;
  }

  const response = await axiosInstance.get(url);
  return response.data as any;
}